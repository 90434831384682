import React from "react";
import { userDevice } from "helpers/tracking";
import alert from "../../../../app/assets/images/icons/alert.svg";

const Training = (props) => {
  const {
    title,
    public_learner,
    due_status,
    due_on,
    status,
    link_path,
    external,
    external_content_url,
    latest_completed_at_formatted,
  } = props.training;

  const redirectToCourse = () => {
    if (external && !!external_content_url) {
      window.open(external_content_url, "_blank");
      return;
    }

    if (external) {
      return;
    }

    window.location.href = link_path;
  };

  const trackEvent = () => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_my_learning_training_clicked",
      {
        training: title,
        status: status,
        is_public_lerner: public_learner,
        user_device: userDevice(),
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  const StatusChip = ({ status, latest_completed_at_formatted }) => {
    return (
      <>
        {status === "completed" && (
          <span className="w-full rounded-none bg-green-50 px-5 py-1 text-center text-sm font-semibold text-gray-70 md:w-1/2">
            {i18n.t("shared.my_learning.table.row.statuses.completed")}{" "}
            {latest_completed_at_formatted}
          </span>
        )}
        {status === "failed" && (
          <span className="w-full rounded-none bg-red-20 px-5 py-1 text-center text-sm font-semibold text-red-100 md:w-1/2">
            {i18n.t("shared.my_learning.table.row.statuses.failed")}
          </span>
        )}
        {(status === "not_started" || status === "in_progress") && (
          <span className="w-full rounded-none bg-gray-25 px-5 py-1 text-center text-sm font-semibold text-gray-70 md:w-1/2">
            {i18n.t(`shared.my_learning.table.row.statuses.${status}`)}
          </span>
        )}
      </>
    );
  };

  return (
    <div
      onClick={() => {
        trackEvent();
        redirectToCourse();
      }}
      className={`my-2 shadow-md ${
        !!external_content_url || (!external && "cursor-pointer")
      }`}
    >
      <div
        className={`items-center justify-around rounded px-2 py-2 md:flex md:py-4 ${
          due_status === "Overdue"
            ? "bg-red-20"
            : due_status === "Due soon"
            ? "bg-yellow-25"
            : "bg-white"
        }`}
      >
        <div className="flex-wrap md:flex md:w-3/4 ">
          <div className="mb-2 md:mb-0">
            <span className="text-lg font-light">{title}</span>
            {external && (
              <span className="ml-3 bg-blue-25 p-1 text-sm font-light">
                External
              </span>
            )}
          </div>

          {!public_learner && (
            <div className="mx-0 flex items-center text-center md:mx-2">
              {due_status === "Overdue" && (
                <>
                  <img src={alert} className="mr-2 h-5" alt="Alert" />
                  <span className="text-xs font-semibold text-red-150">
                    {i18n.t("shared.my_learning.table.row.overdue")} {due_on}
                  </span>
                </>
              )}
              {due_status === "Due soon" && (
                <>
                  <img src={alert} className="mr-2 h-5" alt="Alert" />
                  <span className="text-xs font-semibold text-yellow-150">
                    {due_on}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex justify-end md:w-1/4 md:justify-center">
          <StatusChip
            status={status}
            latest_completed_at_formatted={latest_completed_at_formatted}
          />
        </div>
      </div>
    </div>
  );
};

export default Training;
