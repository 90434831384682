/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "../src/application.scss";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

import "./course_nav.js";
import "./courses.js";
import "./accessibility.js";
import "controllers";
import "./translations.js";
import "./timeZones.js";
import "./stripe.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
// const imagePath = (name) => images(name, true)
require.context("../../../node_modules/bootstrap-icons/icons", true);

if (document.getElementById("menu-open")) {
  document.addEventListener("turbolinks:load", function () {
    document.getElementById("menu-open").addEventListener("click", function () {
      var menu = document.getElementById("mobile-menu-overlay");
      menu.classList.add("fixed");
      menu.classList.remove("hidden");
    });

    document
      .getElementById("menu-close")
      .addEventListener("click", function () {
        var menu = document.getElementById("mobile-menu-overlay");
        menu.classList.add("hidden");
        menu.classList.remove("fixed");
      });
  });
}

var timeZone = jstz.determine().name();
document.cookie = `time_zone=${timeZone}`;

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
