import { Controller } from "stimulus";
import {enter, leave, toggle} from 'el-transition'


export default class extends Controller {
  static targets = ["menu"];

  connect() {
    leave(this.menuTarget)
  }

  toggle(event) {
    toggle(this.menuTarget)
  }
}
