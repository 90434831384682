import React, { useEffect, useState } from "react";
import axios from "axios";

import Loading from "components/Shared/Loading";
import { NotificationContainer } from "react-notifications";

import MatrixFilters from "./MatrixFilters";

import "components/Client/Matrix/matrix.scss";
import "react-notifications/lib/notifications.css";

interface MatrixProps {
  lastUpdated: string;
}

const Matrix: React.FC<MatrixProps> = ({ lastUpdated }) => {
  const baseUrl = window.location.origin;
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [showCareCertificate, setShowCareCertificate] = useState(false);
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [ignoreUseEffect, setIgnoreUseEffect] = useState(true);
  const [careCertificates, setCareCertificates] = useState([])
  const [filteredCareCertificate, setFilteredCareCertificate] = useState([])
  const courseHeaders = filteredCourses.length > 0 ? filteredCourses : courses;

  // FETCH DATA FUNCTIONS

  const fetchMatrixData = async (flag) => {
    setIsLoading(true);

    axios
      .get(fetchUrl)
      .then((response) => {
        const apiResponse = response.data;
        setShowCareCertificate(apiResponse.show_care_certificate);
        setUsers(apiResponse.users);
        setCourses(apiResponse.courses);
        setRoles(apiResponse.roles);
        setLocations(apiResponse.locations);
        setStatuses(apiResponse.statuses);
        setCareCertificates(apiResponse.care_certificates)
        if (!flag.initialPageLoad) {
          setIgnoreUseEffect(true);
          setFilteredCourses(
            apiResponse.filtered_courses.length === apiResponse.courses.length
              ? []
              : apiResponse.filtered_courses
          );
          setFilteredRoles(
            apiResponse.filtered_roles.length === apiResponse.roles.length
              ? []
              : apiResponse.filtered_roles
          );
          setFilteredLocations(
            apiResponse.filtered_locations.length ===
              apiResponse.locations.filter((l) => !!l.id).length
              ? []
              : apiResponse.filtered_locations
          );
          setIgnoreUseEffect(false);
        }
        setIsLoading(false);
      })
      .catch((_error) => {
        setApiError(i18n.t("client.matrix.api_error"));
      });
  };

  const filterParams = (key, filteredValues, values) => {
    if (filteredValues.length == values.length) {
      return "";
    } else {
      return `${filteredValues
        .map((value) => {
          return `${key}_ids[]=${value.id}`;
        })
        .join("&")}`;
    }
  };

  const fetchUrl = `/api/v0/c/matrix?filter_status[]=${filteredStatus}&care_certificate[]=${filteredCareCertificate}&${[
    filterParams("location", filteredLocations, locations),
    filterParams("course", filteredCourses, courses),
    filterParams("role", filteredRoles, roles),
  ]
    .filter(Boolean)
    .join("&")}`;

  const applyAllFilters = (locationsToApply, coursesToApply, rolesToApply) => {
    setIgnoreUseEffect(false);
    setFilteredLocations(locationsToApply);
    setFilteredCourses(coursesToApply);
    setFilteredRoles(rolesToApply);
  };

  // Fetch and set all data for the matrix and filters
  useEffect(() => {
    fetchMatrixData({ initialPageLoad: true });
  }, []);

  useEffect(() => {
    if (ignoreUseEffect) {
      return;
    }
    fetchMatrixData({ initialPageLoad: false });
  }, [filteredLocations, filteredCourses, filteredRoles]);

  useEffect(() => {
    fetchMatrixData({ initialPageLoad: false });
  }, [filteredStatus, filteredCareCertificate]);

  // COPY & STYLING FUNCTIONS

  const green = "bg-green-50";
  const red = "bg-red-50";
  const yellow = "bg-yellow-50";
  const gray = "bg-gray-25";

  const cellClassBgColor = (course_with_required_training) => {
    const status = course_with_required_training.status;

    switch (status) {
      case "Overdue":
        return red;
      case "Due soon":
        return yellow;
      case "One off and completed":
        return green;
      case "Due on":
        return green;
      case "Assigned incomplete":
        return gray;
      default:
        return "";
    }
  };

  const careCertificateBgColor = (careCertificateStatus) => {
    if (careCertificateStatus.complete_certificate_count === 0) {
      return gray;
    } else if (careCertificateStatus.achieved_at) {
      return green;
    } else if (careCertificateStatus.complete_certificate_count > 0) {
      return yellow;
    }
  };

  const careCertificateCopy = (careCertificateStatus) => {
    if (careCertificateStatus.complete_certificate_count === 0) {
      if (careCertificateStatus.due_on) {
        return `Not started, due ${careCertificateStatus.due_on}`
      }

      return i18n.t("client.matrix.care_certificate.not_started");
    } else if (careCertificateStatus.achieved_at) {
      return i18n.t("client.matrix.care_certificate.awarded", {
        date: careCertificateStatus.achieved_at,
      });
    } else if (careCertificateStatus.complete_certificate_count > 0) {
      if (careCertificateStatus.due_on) {
        return `In progress, due ${careCertificateStatus.due_on}`
      }
      return i18n.t("client.matrix.care_certificate.in_progress");
    }
  };

  // TABLE HELPER FUNCTIONS

  const truncate = (text) => {
    return text.length > 15 ? text.substring(0, 15) + "..." : text;
  };

  const renderCourseDetailsTable = () => {
    return (
      <div style={{ position: "relative", zIndex: "0" }}>
        <table
          className="text-sm"
          style={{ borderCollapse: "separate", borderSpacing: "0" }}
        >
          <thead
            className="sticky top-0 whitespace-nowrap"
            style={{ zIndex: "2" }}
          >
            <tr>
              <th
                className="sticky left-0 top-0 bg-gray-25 p-3 text-left align-top font-normal"
                style={{ zIndex: "3" }}
              >
                {i18n.t("client.matrix.tables.name_header")}
              </th>
              <th className="bg-gray-25 p-3 text-left align-top font-normal">
                {i18n.t(
                  "client.matrix.tables.courses.learner_compliance_header"
                )}
              </th>
              {showCareCertificate && (
                <th className="bg-gray-25 p-3 text-left align-top font-normal">
                  {i18n.t(
                    "client.matrix.tables.courses.care_certificate_header"
                  )}
                </th>
              )}
              {courseHeaders.map((course) => {
                return (
                  <th
                    className="min-w-[160px] bg-gray-25 p-3 text-left align-top font-normal"
                    key={course.id}
                  >
                    <div className="group relative flex justify-center">
                      <span className="flex justify-center">
                        {truncate(course.name)}
                      </span>
                      <span
                        className="absolute scale-0 rounded-lg bg-gray-80 p-2 text-white transition-all group-hover:scale-100"
                        style={{ zIndex: "1" }}
                      >
                        {course.name}
                      </span>
                    </div>
                    {course.e_learning && (
                      <span className="mt-2 block w-full p-1 text-center text-xs text-gray-80">
                        {i18n.t(
                          "client.matrix.tables.courses.elearning_header"
                        )}
                      </span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {users.map((user) => {
              return (
                <tr key={user.id}>
                  <td
                    className="matrix-border sticky left-0 w-8 whitespace-nowrap bg-white px-3 py-1"
                    key={user.id}
                  >
                    <a
                      href={baseUrl + "/c/users/" + user.id}
                      className="underline"
                    >
                      {user.name}
                    </a>
                  </td>
                  <td className="matrix-border p-1" key={user.compliance}>
                    <span className="flex justify-center py-1">
                      {user.compliance}%
                    </span>
                  </td>
                  {showCareCertificate && (
                    <td
                      className={`matrix-border whitespace-nowrap px-2 py-1 ${careCertificateBgColor(
                        user.care_certificate_status
                      )}`}
                      key={user.care_certificate_status}
                    >
                      <span className="flex justify-center py-1">
                        {careCertificateCopy(user.care_certificate_status)}
                      </span>
                    </td>
                  )}
                  {user.courses.map((course) => {
                    if (!!course.status) {
                      return (
                        <td
                          className={`${cellClassBgColor(
                            user.courses.find((c) => c.id === course.id)
                          )} matrix-border`}
                          key={course.id}
                        >
                          <span className={`flex justify-center py-1`}>
                            {
                              user.courses.filter((c) => c.id === course.id)[0]
                                .due_on_date
                            }
                          </span>
                        </td>
                      );
                    } else {
                      return (
                        <td
                          className="matrix-border whitespace-nowrap"
                          key={course.id}
                        ></td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return apiError ? (
    <h2 className="text-center text-2xl text-gray-100">{apiError}</h2>
  ) : isLoading ? (
    <Loading marginTop="mt-72" />
  ) : (
    <div className="flex flex-col">
      {
        <>
          <p className={`${showCareCertificate ? "" : "mb-10"}`}>
            Matrix last updated: {lastUpdated}
          </p>
          {showCareCertificate && (
            <p className="mb-10 mt-2">
              You can also view your staff's care certificate progress{" "}
              <a
                className="text-teal-75 hover:text-teal-100"
                href="/c/users?report=care-certificate"
              >
                here
              </a>
            </p>
          )}
          <MatrixFilters
            courses={courses}
            users={users}
            filteredCourses={filteredCourses}
            setFilteredCourses={setFilteredCourses}
            locations={locations}
            filteredLocations={filteredLocations}
            setFilteredLocations={setFilteredLocations}
            roles={roles}
            filteredRoles={filteredRoles}
            setFilteredRoles={setFilteredRoles}
            statuses={statuses}
            filteredStatus={filteredStatus}
            setFilteredStatus={setFilteredStatus}
            careCertificates={careCertificates}
            filteredCareCertificate={filteredCareCertificate}
            setFilteredCareCertificate={setFilteredCareCertificate}
            applyAllFilters={applyAllFilters}
            showCareCertificate={showCareCertificate}
          />
        </>
      }
      {isLoading ? (
        <Loading marginTop="mt-72" />
      ) : users.length === 0 || courses.length === 0 ? (
        <h2 className="p-2 text-lg text-gray-100">
          {i18n.t("client.matrix.empty_state_title")}
        </h2>
      ) : (
        <div className="table-wrapper mb-[200px] max-h-[80vh] overflow-y-auto">
          {renderCourseDetailsTable()}
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};

export default Matrix;
